.filters {
  display: flex;
  flex-direction: column;
  padding: 0;
  padding-bottom: 16px;
  height: 100%;
}

.dateRange {
  width: 100%;
}

.field + .field {
  margin-top: 16px;
}
