@use '/src/packages/assets/styles/typography';

.modal {
  max-width: 437px;
  padding: 16px;
  padding-bottom: 24px;
  border-radius: 8px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  column-gap: 16px;
}

.img {
  min-width: 99px;
}

.title {
  @extend %h3;

  margin-bottom: 8px;
}

.desc {
  @extend %body-m;

  font-size: 13px;
  line-height: 16px;
}

.buttonsWrapper {
  justify-content: center;
  display: flex;
  column-gap: 16px;
}

.button {
  width: 100%;
}
