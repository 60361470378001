.ActionsBarWrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 31px;
}

.WidgetWrapper  {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  margin: 0;
  padding-right: 31px;
  font-size: 14px;
  position: relative;
  overflow: hidden;
}

.LoaderWrapper  {
  display: flex;
  width: 100%;
  height: 100%;
  margin: auto;
}


.TranslationPanelText {
  font-size: 13px;
  line-height: 16px;
}

.NoBroadcast {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
  width: 100%;

  font-size: 14px;
  line-height: 20px;
  color: var(--go-text);
  opacity: .6;

  padding: 16px;
}
