.openButton {
  z-index: 999;
  position: fixed;
  bottom: 24px;
  width: 40px;
  height: 40px;
  display: grid;
  place-items: center;
  font-size: 32px;
  left: 12px;
  border: none;
  color: var(--text-1);
  background: var(--main-wrapper-layout-bg);
  border-radius: 12px;
  cursor: pointer;
  transition: opacity 0.2s, visibility 0.2s;

  &__active {
    visibility: hidden;
    opacity: 0;
  }
}