.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  height: 100%;
  padding: 8px;
  padding-top: 4px;
}

.filters {
  display: flex;
  flex-direction: column;
  padding: 0 2px;
  padding-bottom: 16px;
  height: 100%;
  overflow: auto;
}

.buttons {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  padding: 0 2px;
}

.button {
  width: 100%;
}

.editModeButtons {
  display: flex;
  justify-content: space-between;
  column-gap: 16px;
}
