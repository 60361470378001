@use 'src/packages/assets/styles/typography';

.dates {
  display: flex;
  padding-top: 10px;
}

.date {
  @extend .body-s;
  color: var(--text-1);
}

.dash {
  @extend .body-s;
  color: var(--text-1);
  margin: 0 4px;
}
