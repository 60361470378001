.wellSelect {
	width: 176px;
}

.drillingIcon {
	color: var(--well-list-icons);
	margin-right: 8px;
	min-width: 16px;
	font-size: 16px;
	width: 16px;
	height: 16px;
}