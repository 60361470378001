.wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-top: 8px;
}

.textWrapper {
  display: flex;
  gap: 8px
}

.versionType {
  min-width: 93px;
}
