@import 'src/packages/assets/styles/typography';

:global(.go-theme_light) {
  --tabs-menu-item-active-bg: rgba(57, 84, 208, 0.06);
  --tabs-menu-item-input: rgba(40, 43, 49, 0.6);
  --tabs-menu-item-icon: rgba(106, 120, 162, 1);
}

:global(.go-theme_dark) {
  --tabs-menu-item-active-bg: var(--brand-3);
  --tabs-menu-item-input: rgba(255, 255, 255, 0.8);
  --tabs-menu-item-icon: var(--white-color);
}

.popover {
  padding: 0;
  overflow: hidden;
}

.menuItem {
  @extend .dash-body-regular;

  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 32px;
  cursor: pointer;
  display: flex;
  padding: 4px 8px;
  color: var(--text-1);

  &:first-of-type {
    border-top-left-radius: var(--go-tooltip-border-radius);
    border-top-right-radius: var(--go-tooltip-border-radius);
  }

  &:last-of-type {
    border-bottom-left-radius: var(--go-tooltip-border-radius);
    border-bottom-right-radius: var(--go-tooltip-border-radius);
  }

  .tabName {
    @extend .fontBase;

    letter-spacing: -0.025em;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &:hover:not(&__selected) {
    background: var(--hover);
  }

  &:first-child {
    &:hover {
      border-top-left-radius: 8px;
    }
  }

  &:hover .toolsWrapperMain {
    opacity: 1;
    visibility: visible;
  }

  &__selected {
    background: var(--tabs-menu-item-active-bg);
    &:first-child {
      border-top-left-radius: 8px;
    }
  }

  &__disabled {
    cursor: default;
  }
}

.menu {
  padding: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  row-gap: 4px;
}

.toolsWrapper,
.toolsWrapperMain {
  display: flex;
  column-gap: 4px;
}

.toolsWrapperMain {
  opacity: 0;
  margin-left: 8px;
  visibility: hidden;
}


.tabToolIconButton {
  display: grid;
  place-items: center;
  background: none;
  border: none;
  width: 16px;
  height: 24px;
  cursor: pointer;
  font-size: 16px;
  color: var(--tabs-menu-item-icon);
}

.input {
  @extend .fontBase;

  width: 100%;
  flex: 1;
  background: transparent;
  border: none;
  color: var(--tabs-menu-item-input);
  font-size: 14px;
  font-weight: 500;

  &:focus,
  &:active {
    outline: none;
  }
}
