:global(.go-theme_light) {
  --sidebar-logo: var(--brand-1);
}

:global(.go-theme_dark) {
  --sidebar-logo: var(--contrast);
}

.logoWrapper {
  padding-top: 36px;
}

.logoSvg {
  fill: var(--sidebar-logo);
  transition: var(--transition);
}

.logoContainer {
  display: flex;
  padding-left: 24px;
  transition: var(--transition);

  &__gazOpened {
    padding-left: 24px;
    width: 135px;
  }
}

.logoConcealer {
  overflow: hidden;
  transition: var(--transition);

  &__gaz {
    width: 34px;
  }

  &__ink {
    width: 48px;
  }

  &__geo {
    width: 48px;
  }

  &__opened {
    width: 111px;
  }

  &__ePerform {
    width: 142px;
    transform: translate(-10px, -7px);
  }
}
