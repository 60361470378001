.wrapper {
  height: 100%;
  display: flex;
  flex-flow: column;
  overflow: hidden;
}

.contentWrapper {
  height: 100%;
  position: relative;
}

.icon {
  width: 16px;
  height: 16px;
  color: var(--widget-header-icons);
  font-size: 16px;
}

.button {
  padding: 0;
  width: 16px;
  height: 16px;
}

.ImageBroadcastWidgetWrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.noWell {
  white-space: pre-wrap;
  text-align: center;
  max-width: 460px;
  padding: 10px;
  margin: auto;
  color: var(--go-text);
  opacity: .6;
}
