@import 'src/packages/assets/styles/typography';

.tooltip {
  background: transparent;
}

.tab {
  @extend .caption-fs13;

  display: flex;
  align-items: center;
  padding: 0 8px;
  border-radius: 4px;
  background: var(--tab-active);
  color: var(--tab-text-active);
  border: 1px solid var(--tab-border);
  user-select: none;
  outline: none;
  cursor: pointer;

  &:hover {
    cursor: pointer;
  }

  > span {
    padding: 0;

    &:hover,
    &:focus,
    &:active {
      color: inherit;
    }
  }

  &Disabled {
    cursor: default;
    opacity: 0.5;

    &:hover {
      cursor: default;
    }
  }

  &:not(.tabActive) {
    .input {
      color: #ffffff80;
    }
  }
}

.tabName {
  white-space: nowrap;
}

.closeButton {
  display: grid;
  place-items: center;
  width: 20px;
  height: 20px;
  background: none;
  border: none;
  cursor: pointer;

  &Disabled {
    &:hover {
      cursor: default;
    }
  }
}

.input {
  @extend .fontBase;

  background: transparent;
  border: none;
  color: rgba(255, 255, 255, 0.8);
  font-size: 13px;
  font-weight: 500;

  &:focus,
  &:active {
    outline: none;
  }
}

.hiddenSpan {
  position: fixed;
  z-index: -1;
  opacity: 0;
  padding-left: 4px;
  white-space: nowrap;
  font-size: 13px;
  font-weight: 500;
}
