@use 'src/packages/assets/styles/typography';

:global(.go-theme_light) {
  --multicombobox-view-label-bg: rgba(210, 219, 236, 1);
  --multicombobox-view-label-text: var(--text-2);
}

:global(.go-theme_dark) {
  --multicombobox-view-label-bg: rgba(96, 92, 255, 0.4);
  --multicombobox-view-label-text: var(--white-color);
}

.labels {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding-top: 6px;
}

.label {
  @extend .caption-fs13;
  display: flex;
  padding: 4px 6px;
  border-radius: 4px;
  background: var(--multicombobox-view-label-bg);
  color: var(--multicombobox-view-label-text);
}

