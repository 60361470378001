@use '/src/packages/assets/styles/typography';

:global(.go-theme_dark) {
  --image-color: rgba(255, 255, 255, 0.04);
}

:global(.go-theme_light) {
  --image-color: rgba(223, 230, 242, 1);
}

.noWellContainer {

  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.imageContainer {
  width: 35%;
  aspect-ratio: 1 / 1;
  margin-bottom: 24px;
}

.noWellText {
  @extend %body-m;

  white-space: pre-wrap;
  text-align: center;
  color: var(--text-1);
  max-width: 420px;
}
