:root {
  --red-color: rgba(235, 87, 87, 1);
  --white-color: rgba(255, 255, 255, 1);
  --white-color-10: rgba(255, 255, 255, 0.1);
  --white-color-50: rgba(255, 255, 255, 0.5);
  --green-color: rgba(33, 150, 83, 1);
  --purple-color: rgba(101, 98, 245, 1);
  --yellow-color: rgba(242, 201, 76, 1);
}

:root[data-theme='light'] {
  --brand-1: rgba(58, 54, 219, 1);
  --brand-2: rgba(101, 98, 245, 1);
  --brand-3: rgba(58, 54, 219, 0.4);
  --brand-4: rgba(57, 84, 208, 1);
  --border-20: rgba(229, 229, 255, 0.20);
  --primary-1: rgba(241, 244, 250, 1);
  --primary-2: rgba(210, 223, 250, 1);
  --primary-3: rgba(190, 209, 250, 1);
  --secondary-1: rgba(229, 229, 229, 1);
  --contrast: rgba(6, 21, 43, 1);
  --contrast-transparent: rgba(6, 21, 43, 0.75);
  --hover: rgba(223, 230, 242, 1);
  --background-1: var(--white-color);
  --background-2: var(--white-color);
  --comparing-background: rgba(58, 54, 219, 0.2);
  --comparing-background-2: var(--brand-2);
  --comparing-background-3: var(--brand-2);
  --backdrop-transparent: rgba(0, 0, 0, 0.1);
  --new-well-input-disabled-color: var(--primary-3);
  --new-well-disabled-inputs-text-color: rgba(6, 21, 43, 0.5);
  --widget-header-icons: rgba(64, 81, 134, 1);
  --text-1: rgba(31, 44, 86, 1);
  --text-2: rgba(31, 44, 86, 0.80);
  --modal-bg: rgba(239, 243, 249, 1);
  --modal-border: 1px solid rgba(183, 196, 222, 1);
  --settings-item-active: rgba(57, 84, 208, 0.20);
  --settings-grid-column-bg: var(--white-color);
  --settings-grid-icons: rgba(64, 81, 134, 1);
  --settings-grid-border: rgba(223, 230, 242, 1);
  --workbench-item-bg: rgba(247, 249, 252, 1);
}

:root[data-theme='dark'] {
  --brand-1: rgba(96, 92, 255, 1);
  --brand-2: rgba(69, 65, 201, 1);
  --brand-3: rgba(96, 92, 255, 0.4);
  --brand-4: rgba(116, 113, 255, 1);
  --border-20: rgba(229, 229, 255, 0.20);
  --primary-1: rgba(54, 65, 83, 1);
  --primary-2: rgba(40, 49, 63, 1);
  --primary-3: rgba(26, 32, 44, 1);
  --secondary-1: rgba(255, 255, 255, 0.5);
  --secondary-2: rgba(255, 255, 255, 0.1);
  --contrast: rgba(255, 255, 255, 1);
  --contrast-transparent: rgba(255, 255, 255, 0.5);
  --hover: rgba(101, 121, 151, 0.15);
  --background-1: var(--primary-2);
  --background-2: var(--primary-1);
  --comparing-background: var(--brand-3);
  --comparing-background-2: var(--brand-1);
  --comparing-background-3: var(--brand-2);
  --backdrop-transparent: rgba(0, 0, 0, 0.2);
  --new-well-input-disabled-color: transparent;
  --new-well-disabled-inputs-text-color: rgba(255, 255, 255, 0.5);
  --widget-header-icons: var(--white-color);
  --text-1: var(--white-color);
  --text-2: var(--white-color-50);
  --modal-bg: var(--primary-1);
  --modal-border: 1px solid var(--border-20);
  --settings-item-active: var(--brand-3);
  --settings-grid-column-bg: var(--primary-2);
  --settings-grid-icons: var(--white-color);
  --settings-grid-border: var(--secondary-2);
  --workbench-item-bg: var(--primary-2);
}
