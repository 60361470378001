@use 'src/packages/assets/styles/typography';
@use 'src/packages/assets/styles/templates';

:global(.go-theme_light) {
  --sidebar-menu-item-active-bg-color: rgba(241, 244, 250, 1);
  --sidebar-item-icon: rgba(64, 81, 134, 1);
  --sidebar-item-icon-active: var(--brand-4);
}

:global(.go-theme_dark) {
  --sidebar-menu-item-active-bg-color: rgba(26, 32, 44, 0.3);
  --sidebar-item-icon: var(--white-color);
  --sidebar-item-icon-active: var(--brand-1);
}

$notifColor: rgba(255, 105, 180, 1);
$notifBgColor: rgba(255, 105, 180, 0.1);

.wrapper {
  @extend %ghost-button;

  width: 100%;
  height: 48px;
  margin-bottom: 8px;
  padding-left: 24px;
  display: flex;
  align-items: center;
  transition: var(--transition);

  &:disabled {
    cursor: not-allowed;
  }

  &:not(:disabled, &__active) {
    &:hover {
      background-color: rgba(96, 92, 255, 0.15);
    }
  }

  &__active {
    background-color: var(--sidebar-menu-item-active-bg-color);
    &:hover {
      background-color: var(--primary-3);
    }
  }

  &__secondaryActive {
    border-radius: 0;
    background-color: rgba(96, 92, 255, 0.15);

    &:hover {
      background-color: rgba(96, 92, 255, 0.1);
    }
  }

  &:focus-visible {
    outline: 1px solid var(--contrast);
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.textWrapper {
  opacity: 0;
  visibility: hidden;
  transition: var(--transition);
  display: flex;
  align-items: center;

  &__opened {
    visibility: visible;
    opacity: 1;
  }
}

.text {
  @extend %h3;
  @extend %text-ellipsis;

  color: var(--contrast-transparent);
  margin-bottom: 0;

  &__active {
    color: var(--brand-1);
    opacity: 1;
  }

  &__secondaryActive {
    color: var(--contrast);
  }
}

.iconWrapper {
  width: 24px;
  height: 24px;
  font-size: 24px;
  line-height: 24px;
  margin-right: 16px;
  transition: var(--transition);
  position: relative;
}

.icon {
  color: var(--sidebar-item-icon);
  fill: var(--sidebar-item-icon);
  stroke: var(--sidebar-item-icon);
  opacity: 0.75;
  transition: var(--transition);

  &__active {
    fill: var(--sidebar-item-icon-active);
    stroke: var(--sidebar-item-icon-active);
    color: var(--sidebar-item-icon-active);
    opacity: 1;
  }

  &__dark {
    opacity: 1;
  }

  &__secondaryActive {
    fill: var(--contrast);
    stroke: var(--contrast);
    color: var(--contrast);
  }
}

.notificationsIconBall {
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background-color: $notifColor;
  position: absolute;
  top: 4px;
  right: 4px;
  z-index: 1;
}

.notificationsCount {
  color: $notifColor;
  font-weight: 400;
  min-width: 16px;
  height: 16px;
  padding: 0 4px;
  margin-bottom: 0;
  margin-left: 16px;
  border-radius: 7px;
  background-color: $notifBgColor;
}
