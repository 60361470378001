@use '/src/packages/assets/styles/typography';

.modal {
  max-width: 437px;
  padding: 16px 16px 24px;
}

.header {
  display: flex;
  align-items: center;
  column-gap: 16px;
}

.img {
  min-width: 99px;
}

.title {
  @extend %h3;
  color: var(--text-1);
  margin-bottom: 8px;
}

.desc {
  @extend %body-m;
  color: var(--text-1);
  font-size: 13px;
  line-height: 16px;
}

.button {
  margin-top: 24px;
  width: 100%;
}