@use 'src/packages/assets/styles/typography';
@use 'src/packages/assets/styles/templates';

:global(.go-theme_light) {
  --filter-input-label: rgba(40, 43, 49, 0.6);
}

:global(.go-theme_dark) {
  --filter-input-label: var(--contrast-transparent);
}

.test {
  height: 0;
  position: relative;
}

.label {
  @extend .caption;
  @extend %text-ellipsis;

  max-width: 100%;
  height: min-content;
  margin-bottom: 2px;
  color: var(--filter-input-label);
  transition: var(--transition);
  position: absolute;
  bottom: 0;
}
