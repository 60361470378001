@use '/src/packages/assets/styles/typography';

.wrapper {
  @extend .fontBase;
  height: 100%;
}

.header {
  @extend .body-m-bold;

  display: flex;
  justify-content: space-between;
  column-gap: 12px;
  align-items: center;
  padding: 8px 16px 7px;
  border-bottom:  1px solid var(--settings-grid-border);
  color: var(--contrast);
}

.secondLevelColumn {
  width: 100%;
  background: var(--settings-grid-column-bg);
  border-radius: 8px;
  @extend .fontBase;

  &Item {
    padding: 16px;
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;

    &__active {
      background: var(--settings-item-active);
    }
  }
}

.column {
  @extend .secondLevelColumn;

  max-width: 100%;
}

.header .iconButton .icon {
  color: var(--settings-grid-icons);
  width: 24px;
  height: 24px;
  font-size: 24px;
}

.iconButton {
  background: none;
  font-size: 24px;
  width: 24px;
  height: 24px;
  padding: 0;
}

.headerButton {
  display: flex;
  column-gap: 12px;
}

.content {
  padding: 16px;
}

.templateDetails {
  margin-bottom: 26px;

  .field {
    padding: 4px 8px;
    border-radius: 4px;
    background: var(--widget-template-details-input-bg);
    color: var(--text-1);
    border: none;

    &Name {
      box-shadow: none;
      margin-bottom: 8px;
    }

    &Desc {
      @extend .body-s;
      resize: none;
      overflow-y: hidden;
    }

    &Input {
      @extend .body-s;
    }
  }
}

.descFieldWrapper {
  border: none;
  box-shadow: none;
  width: 100%;
  max-width: 100%;
  margin-bottom: 16px;
}

.duplicateButton {
  padding: 0;
  margin-left: 4px;
}

.widgets {
  padding-left: 8px;


  &Header {
    @extend .caption-fs13;
    margin-bottom: 8px;
    padding: 4px 0;
    border-bottom: 1px solid var(--settings-grid-border);
    color: var(--text-2);
  }

  &List {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    list-style: none;
    margin: 0;
  }

  &Name {
    @extend .caption-fs13;
    margin-bottom: 8px;
  }

  &Item {
    opacity: 0.5;
  }
}

.widget {
  @extend .caption-fs13;
  display: flex;
  column-gap: 8px;
  color: var(--text-1);

  &Number {
    width: 24px;
  }
}
