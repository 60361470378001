@use 'src/packages/assets/styles/templates';
@use 'src/packages/assets/styles/typography';;

.wrapper {
  width: 100%;
  height: 96px;
  padding: 32px 36px 24px 16px;
  display: flex;
  align-items: center;
  position: relative;

  &::after {
    content: '';
    width: 100%;
    height: 1px;
    background-color: var(--contrast);
    opacity: 0.05;
    position: absolute;
    top: 0;
    left: 0;
  }

  &__opened {
    padding-left: 24px;
  }
}

.photoWrapper {
  min-width: 40px;
  min-height: 40px;
  border-radius: 8px;
  margin-right: 16px;
  overflow: hidden;
}

.photo {
  object-fit: cover;
  width: 40px;
  height: 40px;
}

.nameAndLogoutWrapper {
  min-width: 172px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: var(--transition);

  &__opened {
    opacity: 1;
    visibility: visible;
  }
}

.text {
  @extend %text-ellipsis;

  color: var(--contrast);
  max-width: 140px;
  margin-bottom: 0;
}

.logoutButton {
  @extend %ghost-button;

  display: flex;
  font-size: 24px;
  color: var(--text-1);
  align-items: center;
  justify-content: center;
  fill: var(--contrast);
}
