:global(.go-theme_light) {
  --single-tab-overlay-backgroundColor: rgba(0, 0, 0, 0.1);
  --single-tab-header-bg: rgba(245, 248, 251, 1);
  --single-tab-header-border-btm: rgba(223, 230, 242, 1);
  --single-tab-icons: rgba(40, 43, 49, 0.8);
}

:global(.go-theme_dark) {
  --single-tab-overlay-backgroundColor: rgba(0, 0, 0, 0.3);
  --single-tab-header-bg: var(--primary-2);
  --single-tab-header-border-btm: var(--primary-3);
  --single-tab-icons: var(--white-color);
}

.wrapper {
  height: 100%;
  width: 100%;
  flex-grow: 1;

  &FullscreenMode {
    overflow-y: hidden;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  padding: 4px;
  height: 34px;
  position: fixed;
  max-width: 100vw;
  width: 100%;
  z-index: 999;
  background: var(--single-tab-header-bg);
  border-bottom: 2px solid var(--workspace-header-border-btm);
}

.loader {
  display: grid;
  place-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: var(--single-tab-overlay-backgroundColor);
}

.toolbar {
  display: flex;
  justify-content: space-between;
  column-gap: 10px;
  align-items: center;
  padding-left: 8px;

  &Icon {
    display: grid;
    place-items: center;
    background: none;
    width: 24px;
    color: var(--single-tab-icons);
    padding: 0;
    height: 24px;

    &:hover {
      border-radius: 4px;
      background: var(--hover);
    }

    .icon {
      width: 16px;
      height: 16px;
    }
  }
}

.widgetsToolbar {
  display: flex;
  column-gap: 16px;
  align-items: center;
  padding-left: 16px;
  border-left: 1px solid var(--secondary-2);
}
