@import 'src/packages/assets/styles/typography';

:global(.go-theme_light) {
  --tab-tooltip-widget-name: rgba(40, 43, 49, 0.6);
  --tab-tooltip-name-external: rgba(40, 43, 49, 0.60);
  --tab-tooltip-name-external-bg: rgba(80, 101, 155, 0.10);
  --tab-tooltip-name-external-icon: rgba(40, 43, 49, 0.60);
  --tab-tooltip-tab-actions-hover: rgba(57, 84, 208, 0.06);
  --tab-tooltip-divider: rgba(80, 101, 155, 0.10)
}

:global(.go-theme_dark) {
  --tab-tooltip-widget-name: rgba(255, 255, 255, 0.60);
  --tab-tooltip-name-external: rgba(255, 255, 255, 0.4);
  --tab-tooltip-name-external-bg: rgba(7, 7, 19, 0.3);
  --tab-tooltip-name-external-icon: rgba(120, 140, 185, 1);
  --tab-tooltip-tab-actions-hover: var(--hover);
  --tab-tooltip-divider: var(--white-color-10);
}

.contentWrapper {
  @extend .fontBase;
  white-space: nowrap;
  width: max-content;
}

.tabName {
  @extend .fontBase;
  @extend  .h4-lh16;
  display: flex;
  align-items: center;
  column-gap: 8px;
  padding: 6px 16px;

  &__external {
    color: var(--tab-tooltip-name-external);
    background: var(--tab-tooltip-name-external-bg);
  }

  &Icon {
    width: 16px;
    height: 16px;
    color: var(--tab-tooltip-name-external-icon);
  }
}

.buttonsBox {
  margin-top: 4px;
  margin-bottom: 4px;
  gap: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.tabActionButton {
  @extend .caption-fs13;
  justify-content: flex-start;
  width: 100%;
  padding: 5px 16px;
  color: var(--brand-4);
  border: none;
  border-radius: 4px;
  background: transparent;

  &:hover {
    border: none;
    background: var(--tab-tooltip-tab-actions-hover);
  }

  &Icon {
    color: var(--brand-4);
    width: 16px;
    height: 16px;
  }
}

.widgetsList {
  padding: 8px 8px 0 16px;
  margin: 0;
  list-style: none;
  font-weight: 500;
}

.widgetName {
  @extend .caption-fs13;
  color: var(--tab-tooltip-widget-name);
  padding-bottom: 8px;
}

.divider {
  border-bottom: 1px solid var(--tab-tooltip-divider);
  height: 1px;
  display: flex;
}
