@use '/src/packages/assets/styles/typography';

.loader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 12;
}

.column {
  @extend .fontBase;

  width: 100%;
  background: var(--settings-grid-column-bg);
  border-radius: 8px;
  position: relative;
  max-width: 544px;
  overflow: hidden;

  &Item {
    padding: 16px;
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;

    &__active {
      background: var(--settings-item-active);
    }
  }
}

.importButton {
  height: 24px;
  width: 24px;
  padding: 0;
  background: none;
  border: none;
  color: var(--settings-grid-icons);
  cursor: pointer;
}

.icon {
  font-size: 24px;
}
