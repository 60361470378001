.wrapper {
	height: 100%;
	display: flex;
	flex-flow: column;
	overflow: hidden;
  }
  
  .contentWrapper {
	height: 100%;
	position: relative;
  }
  
  .icon {
	width: 16px;
	height: 16px;
	color: var(--widget-header-icons);
	font-size: 16px;
  }
  
  .button {
	padding: 0;
	width: 16px;
	height: 16px;
  }
  
  .widgetWrapper {
	height: 100%;
	display: flex;
	flex-direction: column;
  }
  
  .directionalDrillingTitle {
	min-width: auto;
  }
  
  .directionalDrillingName {
	min-width: auto;
  }