@use '/src/packages/assets/styles/typography';

.secondLevelColumn {
  @extend .fontBase;

  width: 100%;
  background-color: var(--settings-grid-column-bg);
  border-radius: 8px;
  overflow: hidden;
  padding: 24px;
}
