$handle-width: 8px;
$handle-offset: calc(-1 * ($handle-width / 2));

.react-grid-item > .react-resizable-handle {
  &.react-resizable-handle {
    &-s,
    &-se,
    &-e,
    &-ne,
    &-n,
    &-nw,
    &-w,
    &-sw {
      opacity: 0.5;
      transform: none;
      background-image: none;

      &::after {
        display: none;
        width: 0;
        content: '';
      }
    }

    &-s,
    &-n {
      left: 7px;
      margin-left: 0;
      width: calc(100% - 14px);
      height: $handle-width;
    }

    &-s {
      bottom: $handle-offset;
    }

    &-n {
      top: $handle-offset;
    }

    &-se {
      right: $handle-offset;
      bottom: $handle-offset;
      height: $handle-width;
      width: $handle-width;
    }

    &-ne {
      right: $handle-offset;
      top: $handle-offset;
      height: $handle-width;
      width: $handle-width;
    }

    &-nw {
      left: $handle-offset;
      top: $handle-offset;
      height: $handle-width;
      width: $handle-width;
    }

    &-sw {
      left: $handle-offset;
      bottom: $handle-offset;
      height: $handle-width;
      width: $handle-width;
    }

    &-e,
    &-w {
      top: 17px;
      border: 0;
      height: calc(100% - 14px);
      width: $handle-width;
    }

    &-e {
      right: $handle-offset;
    }

    &-w {
      left: $handle-offset;
    }
  }
}
