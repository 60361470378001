@use 'src/packages/assets/styles/typography';

:global(.go-theme_light) {
  --new-content-subheader-bg: #dfe6f2;
  --new-content-subheader-text: rgba(40, 43, 49, 0.6);
}

:global(.go-theme_dark) {
  --new-content-subheader-bg: var(--primary-2);
  --new-content-subheader-text: rgba(255, 255, 255, 0.6);
}

.modal {
  padding: 0;
  min-height: 265px;
  min-width: 506px;
  background-color: var(--modal-bg);
  border: var(--modal-border);
}

.titleWrapper {
  width: 100%;
  height: 36px;
  padding: 0 16px;
  display: flex;
  align-items: center;
}

.titleText {
  @extend .h4;
  color: var(--text-1);
  font-weight: 700;
}

.backButton {
  margin-right: 24px;
}

.subheader {
  width: 100%;
  height: 32px;
  padding: 0 16px;
  background-color: var(--new-content-subheader-bg);
  display: flex;
  align-items: center;

  &Text {
    @extend .caption-fs13;
    color: var(--new-content-subheader-text);
  }
}

.contentWrapper {
  padding: 36px 16px 40px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 24px;
}
