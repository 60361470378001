:global(.go-theme_light) {
  --main-layout-bg: var(--primary-1);
}

:global(.go-theme_dark) {
  --main-layout-bg: var(--primary-3);
}

.loaderContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--main-layout-bg);
}
