@use '/src/packages/assets/styles/typography';

.secondLevelColumn {
  @extend .fontBase;

  max-width: 270px;
  width: 100%;
  background-color: var(--settings-grid-column-bg);
  border-radius: 8px;
  overflow: hidden;

  &Item {

    padding: 16px;
    cursor: pointer;

    &__active {
      background: var(--settings-item-active);
    }
  }
}
