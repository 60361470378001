
.wrapper {
}

.intervalWrapper {
  display: flex;
  align-items: center;
  gap: 4px;
  width: 100%;
}

.dash {
  font-size: 8px;
  color: var(--contrast);
}

.input {
  min-width: 150px;
  width: 100%;
}
