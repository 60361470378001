@use 'src/packages/assets/styles/typography';

:global(.go-theme_light) {
  --group-icon: rgba(183, 196, 222, 1);
}

:global(.go-theme_dark) {
  --group-icon: var(--white-color);
}

.wrapper {
  margin-right: 10px;
  width: 16px;
  height: 16px;
  line-height: 1;
}

.addGroupButton {
  padding: 0;
  width: 16px;
  height: 16px;
  background: none;
  cursor: pointer;
}

.groupsList {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  list-style: none;
  margin-bottom: 0;
}

.groupItem {
  width: 100%;
}

.groupButton {
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: 10px;
  padding: 4px 8px;
  border: none;
  background: var(--primary-2);
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background: var(--hover);
  }
}

.groupCircle {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-bottom: 0;
}

.groupName {
  @extend %body-m;

  color: var(--contrast);
}

.groupIcon {
  color: var(--group-icon);
  width: 16px;
  height: 16px;
}

.clearGroupButton {
  display: grid;
  place-items: center;
  width: 16px;
  height: 16px;
  font-size: 16px;
  border: none;
  background: none;
  cursor: pointer;

  &__disabled {
    opacity: 0.5;
  }

  &:hover {
    .clearGroupCircle {
      display: none;
    }

    .clearGroupCircle__disabled {
      display: block;
      cursor: not-allowed;
    }

    .clearGroupIcon {
      display: block;
      opacity: 1;
      visibility: visible;
    }

    .clearGroupIcon__disabled {
      display: none;
    }
  }
}


.clearGroupCircle {
  width: 12px;
  height: 12px;
  margin: 0;
  border-radius: 50%;
}

.clearGroupIcon {
  display: none;
  transform: rotate(45deg);
  opacity: 0;
  visibility: hidden;
}
