:global(.go-theme_light) {
  --main-wrapper-layout-bg: rgba(225, 228, 238, 1);
}

:global(.go-theme_dark) {
  --main-wrapper-layout-bg: var(--primary-3);
}

.wrapper {
  width: 100%;
  height: 100%;
  min-width: 320px;
  min-height: 590px;
  padding-left: 0;
  display: flex;
  flex-flow: column;
  background-color: var(--main-wrapper-layout-bg);
  transition: var(--transition);
  position: relative;

  @media (min-resolution: 101dpi) and (max-width: 1679px) {
    padding-left: 0;
  }

  :global(.Toastify__toast-container--top-right) {
    top: 36px;
  }
}

.contentWrapper {
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.childrenWrapper {
  width: 100%;
  height: 100%;
  flex-grow: 1;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
