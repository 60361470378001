:global(.go-theme_light) {
  --toolbar-icons: rgba(64, 81, 134, 1);
  --toolbar-break: rgba(223, 230, 242, 1);
}

:global(.go-theme_dark) {
  --toolbar-icons: var(--white-color);
  --toolbar-break: var(--secondary-2);
}

.buttonsWrapper {
  display: flex;
  justify-content: flex-end;
  column-gap: 10px;
  height: 24px;
}

.toolbar {
  display: flex;
  justify-content: flex-end;
  column-gap: 10px;
  align-items: center;
  padding-left: 8px;

  &Icon {
    display: grid;
    place-items: center;
    width: 24px;
    padding: 0;
    color: var(--toolbar-icons);
    height: 24px;

    &:hover {
      border-radius: 4px;
      background: var(--hover);
    }

    .icon {
      width: 16px;
      height: 16px;
    }
  }
}

.tabsButtons {
  display: flex;
  align-items: center;
}

.widgetsToolbar {
  display: flex;
  column-gap: 16px;
  align-items: center;
  padding-left: 16px;
  border-left: 1px solid var(--toolbar-break);
}

.addTabButton {
  display: grid;
  place-items: center;
  background: none;
  border: none;
  width: 40px;
  height: 24px;
  font-size: 16px;
  padding: 0;
  margin-right: 4px;
  cursor: pointer;
  color: var(--toolbar-icons);

  .icon {
    width: 16px;
    height: 16px;
    font-size: 16px;
  }
}

.wellListPopup {
  background-color: var(--workbench-item-bg);
}
