@use '/src/packages/assets/styles/typography';

.modal {
  min-width: 437px;
  padding: 16px 16px 24px;
  border-radius: 8px;
  background-color: var(--modal-bg);
  border: var(--modal-border);
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  column-gap: 16px;
}

.img {
  min-width: 99px;
}

.icon {
  width: 16px;
  height: 16px;
}

.title {
  @extend %h3;
  color: var(--text-1);
  margin-bottom: 8px;
}

.desc {
  @extend %body-m;
  color: var(--text-1);
  font-size: 13px;
  line-height: 16px;
}

.buttonsWrapper {
  justify-content: center;
  display: flex;
  column-gap: 16px;
}

.button {
  width: 100%;
  white-space: nowrap;
}
