.wrapper {
  &__opened {
    padding-right: 24px;
  }
}

.bellIcon {
  fill: none;
  stroke: none;
}

.settingsIcon {
  stroke: none;
}

.questionIcon {
  fill: none;
}