@use '/src/packages/assets/styles/typography';

.wrapper {
  height: 100%;
}

.header {
  @extend .h3;
  color: var(--contrast);
  padding: 6px 16px;
}

.contentWrapper {
  display: flex;
  min-height: calc(100% - 32px);
  column-gap: 8px;
  padding: 0 20px 16px 8px;
}

.grid {
  display: flex;
  width: 100%;
  column-gap: 16px;
}

.mainColumn {
  width: 100%;
  max-width: 254px;
  margin: 0;

  &Icon {
    width: 24px;
    height: 24px;
    font-size: 24px;
    color: var(--settings-grid-icons);
  }

  &Item {
    list-style: none;
  }

  &Button {
    @extend .semi-bold;

    width: 100%;
    display: flex;
    align-items: center;
    column-gap: 16px;
    padding: 16px 8px;
    border-radius: 8px;
    border: none;
    background: none;
    cursor: pointer;
    color: var(--contrast);

    &__active {
      background: var(--settings-item-active);
    }
  }
}

.secondLevelColumn {
  max-width: 270px;
  width: 100%;
  background: var(--primary-2);
  border-radius: 8px;
  @extend .fontBase;

  &Item {
    padding: 16px;
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;

    &__active {
      background: var(--settings-item-active);
    }
  }
}

.thirdLevelColumn {
  @extend .secondLevelColumn;

  max-width: 544px;
}

.importButton {
  height: 24px;
  width: 24px;
  padding: 0;
  background: none;
}
