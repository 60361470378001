@use '/src/packages/assets/styles/typography';


.list {
  display: flex;
  list-style: none;
  flex-direction: column;
  row-gap: 8px;
  padding: 8px 0;
  margin: 0;
}

.item {
  width: 100%;

  &__active {
    background: var(--settings-item-active);
  }

  &Button {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 4px 16px;
    min-height: 44px;
    font-size: 13px;
    line-height: 16px;
    background: none;
    border: none;
    cursor: pointer;
  }

  &TitleWrapper {
    text-align: left;
  }

  &Name {
    @extend .caption-fs13;
    color: var(--text-1);
    margin-bottom: 4px;
    word-break: break-all;
  }

  &Desc {
    @extend .body-s;
    color: var(--text-2);
    word-break: break-all;
  }
}

.icon {
  color: var(--brand-4);
  margin-right: 6px;
  font-size: 16px;
}
