.wrapper {
  height: calc(100% - 25px);
  display: flex;
  flex-flow: column;
}

.contentWrapper {
  height: 100%;
  position: relative;
}

.icon {
  width: 16px;
  height: 16px;
  color: var(--widget-header-icons);
  font-size: 16px;
}

.button {
  padding: 0;
  width: 16px;
  height: 16px;
}

.wellDetailsWidgetWrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}
