@use '/src/packages/assets/styles/typography';

.collapse {
  @extend .fontBase;

  --go-collapse-body-padding-top: 0;
  --go-collapse-body-padding-right: 0;
  --go-collapse-body-padding-left: 0;
  --go-collapse-body-padding-bottom: 0;

  &Header {
    padding: 7px 16px;
    display: flex;
    align-items: center;
    border-bottom:  1px solid var(--settings-grid-border);
    cursor: pointer;
    color: var(--contrast);
    background-color: var(--settings-grid-column-bg);
  }

  &Title {
    @extend .body-m-bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-left: 4px;
  }
}

.arrowIcon {
  transition: var(--transition);
  opacity: 0.5;
  width: 24px;
  height: 24px;
  font-size: 24px;

  &Up {
    transform-origin: 50% 50%;
    transform: rotate(180deg);
  }
}
